import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {LoginComponent} from './layouts/login/login.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {GlobalService, MomentService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {MomentDatePipe} from './shared/pipes/moment-date.pipe';
import {ColorPickerModule} from 'ngx-color-picker';
import {MyProfileComponent} from './pages/my-profile/my-profile.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {NgxPrintModule} from 'ngx-print';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {PlacementComponent} from './pages/placement/placement.component';
import {ArchwizardModule} from 'angular-archwizard';
import {CandidatesComponent} from './pages/candidates/candidates.component';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    LoginComponent,
    MomentDatePipe,
    DashboardComponent,
    MyProfileComponent,
    PlacementComponent,
    NotFoundComponent,
    CandidatesComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    SnotifyModule,
    ColorPickerModule,
    BsDatepickerModule.forRoot(),
    NgxPrintModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ArchwizardModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    HtpInterceptor,
    GlobalService,
    AlertService,
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
    MomentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
