import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {GlobalService} from '../../shared/services/global.service';
import {AuthService} from '../../shared/services/auth.service';
import {StorageService} from '../../shared/services/storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../shared/services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginUserForm: FormGroup;
  registrationUserForm: FormGroup;
  otpVerificationForm: FormGroup;
  isBusy = false;
  loginPressed = false;
  showLogin: any = false;
  submitted: any = false;
  submitting: any = false;
  code: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              private globalService: GlobalService,
              private authService: AuthService,
              public modalService: NgbModal,
              public alertService: AlertService) {
  }

  ngOnInit() {
    if (this.checkLoggedInStatus()) {
      this.setSelfData();
      this.router.navigateByUrl('');
    }
    this.refresh();
  }

  refresh() {
    this.loginUserForm = this.fb.group({
      'email': ['', Validators.required],
      'password': ['', Validators.required]
    });

    this.registrationUserForm = this.fb.group({
      'name': ['', Validators.required],
      'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
      'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      'password': ['', Validators.required],
      'cnf_password': ['', Validators.required]
    });

    this.otpVerificationForm = this.fb.group({
      'otp': ['', Validators.required]
    });
  }

  registerUser() {
    this.submitted = true;
    /*if (!this.registrationUserForm.valid || !this.otpVerificationForm.valid) {
      return;
    }*/
    if (!this.registrationUserForm.valid) {
      return;
    }

    if (this.submitted) {
      const params = {
        'name': (this.registrationUserForm.getRawValue() && this.registrationUserForm.getRawValue().name) ? this.registrationUserForm.getRawValue().name : '',
        'email': (this.registrationUserForm.getRawValue() && this.registrationUserForm.getRawValue().email) ? this.registrationUserForm.getRawValue().email : '',
        'contact': (this.registrationUserForm.getRawValue() && this.registrationUserForm.getRawValue().contact) ? this.registrationUserForm.getRawValue().contact : '',
        'password': (this.registrationUserForm.getRawValue() && this.registrationUserForm.getRawValue().password) ? this.registrationUserForm.getRawValue().password : '',
        /*'otp': (this.otpVerificationForm.getRawValue() && this.otpVerificationForm.getRawValue().otp) ? this.otpVerificationForm.getRawValue().otp : '',
        'code': this.code,*/
      };
      this.submitting = true;
      this.authService.register(params).subscribe(data => {
        if (data) {
          this.modalService.dismissAll('');
          this.globalService.setAccessToken(data.token);
          this.globalService.setSelf({
            'name': data.name,
            'userId': data.id,
            'email': data.email,
            'contact': data.contact,
            'user_type': data.user_type
          });
          this.registrationUserForm.reset();
          this.otpVerificationForm.reset();
          this.submitting = false;
          this.submitted = false;
          this.router.navigateByUrl('');
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  resendOtp() {
    this.submitted = true;
    if (!this.registrationUserForm.valid) {
      return;
    }

    if (this.submitted) {
      const params = {
        'contact': (this.registrationUserForm.getRawValue() && this.registrationUserForm.getRawValue().contact) ? this.registrationUserForm.getRawValue().contact : ''
      };
      this.submitting = true;
      this.authService.sendOtp(params).subscribe(data => {
        if (data) {
          this.code = data.code;
          this.submitting = false;
          this.submitted = false;
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  getOtp(content) {
    this.submitted = true;
    if (!this.registrationUserForm.valid) {
      return;
    }

    if (this.registrationUserForm.getRawValue().password === this.registrationUserForm.getRawValue().cnf_password) {
      if (this.submitted) {
        const params = {
          'contact': (this.registrationUserForm.getRawValue() && this.registrationUserForm.getRawValue().contact) ? this.registrationUserForm.getRawValue().contact : ''
        };
        this.submitting = true;
        this.authService.sendOtp(params).subscribe(data => {
          if (data) {
            this.code = data.code;
            this.modalService.open(content);
            this.submitting = false;
            this.submitted = false;
          }
        }, error => {
          this.submitting = false;
          this.submitted = false;
        });
      }
    } else {
      this.alertService.showErrors('Password & confirm password didn\'t match');
      this.submitted = false;
      this.submitting = false;
    }
  }

  loginUser() {
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.loginPressed = true;

    this.submitted = true;
    if (!this.loginUserForm.valid) {
      return;
    }

    if (this.checkLoggedInStatus()) {
      this.setSelfData();
      this.router.navigateByUrl('');
    } else {
      this.authService.login(this.loginUserForm.getRawValue()).subscribe(data => {
        if (data.id) {
          this.globalService.setAccessToken(data.token);
          this.globalService.setSelf({
            'name': data.name,
            'userId': data.id,
            'email': data.email,
            'contact': data.contact,
            'user_type': data.user_type
          });
          this.router.navigateByUrl('');
          this.loginUserForm.reset();
          this.loginPressed = false;
        }
      }, error => {
        this.isBusy = false;
        this.loginPressed = false;
      });
    }
  }

  checkLoggedInStatus() {
    const accessToken = StorageService.getItem('accessToken');
    return accessToken && accessToken !== 'null';
  }

  setSelfData() {
    this.authService.self({all: true}).subscribe((data) => {
      this.isBusy = false;
      this.globalService.setSelf({
        'name': data.name,
        'userId': data.id,
        'email': data.email,
        'contact': data.contact,
        'user_type': data.user_type
      });
      return;
    }, e => {
      this.isBusy = false;
      StorageService.clearAll();
    });
  }
}
