import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

  static get API_URL(): string {
    return environment.appUrl + 'api/';
  }

  static get APP_URL(): string {
    return environment.appUrl + 'admin/';
  }

  static get LOGIN(): string {
    return AppUrl.API_URL + 'login';
  }

  static get REGISTER(): string {
    return AppUrl.API_URL + 'register';
  }

  static get SEND_OTP(): string {
    return AppUrl.API_URL + 'send-otp';
  }

  static get SELF(): string {
    return AppUrl.API_URL + 'self';
  }

  static LOGOUT(): string {
    return AppUrl.API_URL + 'logout';
  }

  static get VERIFY_USER(): string {
    return AppUrl.APP_URL + 'verify-user';
  }

  static EXECUTIVES(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'executive/' + id;
    } else {
      return AppUrl.APP_URL + 'executive';
    }
  }

  static EXECUTIVE_PASSWORD(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'executive-password/' + id;
    }
  }

  static EXECUTIVE_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'executive-status/' + id;
    }
  }

  static EXECUTIVES_COUNT(): string {
    return AppUrl.APP_URL + 'executives-count';
  }

  static ADMINS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'admin/' + id;
    } else {
      return AppUrl.APP_URL + 'admin';
    }
  }

  static ADMIN_PASSWORD(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'admin-password/' + id;
    }
  }

  static ADMIN_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'admin-status/' + id;
    }
  }

  static ADMINS_COUNT(): string {
    return AppUrl.APP_URL + 'admins-count';
  }

  static UPDATE_USER_PASSWORD(): string {
    return AppUrl.API_URL + 'update-user-password';
  }

  static STATES(): string {
    return AppUrl.APP_URL + 'states';
  }

  static PERSONAL_INFO(id?): string {
    if (id) {
      return AppUrl.API_URL + 'personal-info/' + id;
    } else {
      return AppUrl.API_URL + 'personal-info';
    }
  }

  static QUALIFICATION(id?): string {
    if (id) {
      return AppUrl.API_URL + 'qualification/' + id;
    } else {
      return AppUrl.API_URL + 'qualification';
    }
  }

  static FILE_UPLOAD(): string {
    return AppUrl.API_URL + 'upload-file';
  }

  static CANDIDATE(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'candidate/' + id;
    } else {
      return AppUrl.APP_URL + 'candidate';
    }
  }

  static CANDIDATE_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'candidate-status/' + id;
    }
  }

  static APPLICATION_DETAIL(id?): string {
    if (id) {
      return AppUrl.API_URL + 'application/' + id;
    } else {
      return AppUrl.API_URL + 'application';
    }
  }

  static APPLICATION_STATUS(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'application-status/' + id;
    }
  }

  static CANDIDATES_COUNT(): string {
    return AppUrl.APP_URL + 'candidates-count';
  }

  static MY_PROFILE(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'my-profile/' + id;
    } else {
      return AppUrl.APP_URL + 'my-profile';
    }
  }

  static MY_PROFILE_UPDATE(id?): string {
    if (id) {
      return AppUrl.APP_URL + 'my-profile-update/' + id;
    }
  }

  static JOB_DETAIL(id?): string {
    if (id) {
      return AppUrl.API_URL + 'job-detail/' + id;
    } else {
      return AppUrl.API_URL + 'job-detail';
    }
  }
}
