import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../shared/services/alert.service';
import {StorageService} from '../../shared/services/storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../shared/services/auth.service';
import {Location} from '@angular/common';
import {MyProfileService} from '../../shared/services/my-profile.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  myProfileForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  inputTypePasswordCurrent: any = 'password';
  inputTypePasswordNew: any = 'password';
  inputTypePasswordConfirm: any = 'password';
  user: any;
  updatePasswordForm: FormGroup;

  constructor(private router: Router,
              private fb: FormBuilder,
              private myProfileService: MyProfileService,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              public modalService: NgbModal,
              private authService: AuthService,
              private _location: Location) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    this.refresh();
  }

  refresh() {
    this.myProfileForm = this.fb.group({
      'name': ['', Validators.required],
      'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])]
    });
    this.updatePasswordForm = this.fb.group({
      'currentPassword': ['', Validators.required],
      'newPassword': ['', Validators.required],
      'confirmPassword': ['', Validators.required]
    });
    if (this.user && this.user['userId']) {
      this.detail();
    }
  }

  detail() {
    this.myProfileService.detail(parseInt(this.user['userId'])).subscribe(data => {
      if (data) {
        this.myProfileForm.patchValue({
          'name': (data && data.name) ? data.name : '',
          'email': (data && data.email) ? data.email : '',
          'contact': (data && data.contact) ? data.contact : ''
        });
      }
    }, error => {
      this.back();
    });
  }

  back() {
    // this.router.navigateByUrl('');
    this._location.back();
  }

  updatePasswordModal(content) {
    this.modalService.open(content, {'size': 'sm'});
  }

  update() {
    this.submitted = true;
    if (!this.myProfileForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.myProfileForm.value && this.myProfileForm.value.name) ? this.myProfileForm.value.name : '',
        'email': (this.myProfileForm.value && this.myProfileForm.value.email) ? this.myProfileForm.value.email : '',
        'contact': (this.myProfileForm.value && this.myProfileForm.value.contact) ? this.myProfileForm.value.contact : ''
      };
      this.submitting = true;
      this.myProfileService.update(this.user['userId'], params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.back();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
            this.alertService.showErrors(error['error']['errors'].email[0]);
            return;
          }
          if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
            this.alertService.showErrors(error['error']['errors'].contact[0]);
            return;
          }
        }
      });
    }
  }

  updatePassword() {
    this.submitted = true;
    if (!this.updatePasswordForm.valid) {
      return;
    }
    if (this.updatePasswordForm.value.confirmPassword !== this.updatePasswordForm.value.newPassword) {
      this.alertService.showErrors('New Password and Confirm Password didn\'t match');
      this.submitted = false;
      return;
    }
    if (this.submitted) {
      const params = {
        'currentPassword': (this.updatePasswordForm.value && this.updatePasswordForm.value.currentPassword) ? this.updatePasswordForm.value.currentPassword : '',
        'newPassword': (this.updatePasswordForm.value && this.updatePasswordForm.value.newPassword) ? this.updatePasswordForm.value.newPassword : ''
      };
      this.submitting = true;
      this.authService.updateSelfPassword(params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.updatePasswordForm.patchValue({
          'currentpassword': '',
          'newPassword': '',
          'confirmPassword': ''
        });
        this.modalService.dismissAll('');
        this.detail();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error']) {
          if (error['error'].errorDetails) {
            this.alertService.showErrors(error['error'].errorDetails);
            return;
          }
        }
      });
    }
  }
}
