import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {StorageService} from '../services/storage.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  toggleClass = 'ft-maximize';
  public isCollapsed = true;
  user: any;

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  logOut() {
    this.authService.logout().subscribe(data => {
      StorageService.clearAll();
      this.router.navigateByUrl('/login');
    }, error => {
      this.router.navigateByUrl('/login');
    });
  }
}
