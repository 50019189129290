import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalInfoService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService,
              public fileUploadService: UploadService) {
    super(http, alertService, globalService);
  }

  get(): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.PERSONAL_INFO());
  }

  detail(id): Observable<any> {
    return this.getRequest(AppUrl.PERSONAL_INFO(id));
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.PERSONAL_INFO(), data);
  }

  update(id, data): Observable<any> {
    return this.postRequest(AppUrl.PERSONAL_INFO(id), data);
  }

  fileUpload(params, event): Observable<any> {
    return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event)
  }
}
