import {Injectable} from '@angular/core';

@Injectable()
export class AppConstants {
  static markingSchemes = [
    {'name': 'Percentage', 'value': 'PERCENTAGE'},
    {'name': 'CGPA', 'value': 'CGPA'}
  ];

  static boardUniversity = [
    {'name': 'CBSE', 'value': 'CBSE'},
    {'name': 'ICSE', 'value': 'ICSE'},
    {'name': 'IB', 'value': 'IB'},
    {'name': 'State Board', 'value': 'STATE_BOARD'}
  ]
}
