import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StateService} from '../../shared/services/state.service';
import {Helper} from '../../shared/constants/helper';
import * as moment from 'moment';
import {BsDatepickerConfig} from 'ngx-bootstrap';
import {Location} from '@angular/common';
import {AlertService} from '../../shared/services/alert.service';
import {PersonalInfoService} from '../../shared/services/personal-info.service';
import {environment} from '../../../environments/environment';
import {QualificationService} from '../../shared/services/qualification.service';
import {GlobalService} from '../../shared/services/global.service';
import {AppConstants} from '../../shared/constants/app-constant';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateService} from '../../shared/services/candidate.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationService} from '../../shared/services/application.service';
import {JobDetailService} from '../../shared/services/job-detail.service';

@Component({
  selector: 'app-placement',
  templateUrl: './placement.component.html',
  styleUrls: ['./placement.component.scss']
})
export class PlacementComponent implements OnInit {
  submittedPersonalInfo: any = false;
  submittingPersonalInfo: any = false;
  personalInfoForm: FormGroup;
  qualificationDetailsForm: FormGroup;
  moreQualificationForm: FormGroup;
  jobDetailForm: FormGroup;
  experienceDetailsForm: FormGroup;
  years = Helper.getYearArray();
  baseUrl = environment.appUrl;
  markingScheme = AppConstants.markingSchemes;
  boardUniversity = AppConstants.boardUniversity;
  states: any = [];
  moreQualifications: any = [];
  onFileChanged: any;
  bsConfig: Partial<BsDatepickerConfig>;
  filePhotographSelected: any;
  fileGovtPhotoIdSelected: any;
  filePhotograph: any;
  fileGovtPhotoId: any;
  fileTenthCertificateSelected: any;
  fileTwelthCertificateSelected: any;
  fileTenthCertificate: any;
  fileTwelthCertificate: any;
  submittedQualificationDetails: any = false;
  submittingQualificationDetails: any = false;
  fileCompletetionCertificateSelected: any;
  fileResumeSelected: any;
  fileExperienceReleivingSelected: any;
  fileCompletetionCertificate: any;
  fileResume: any;
  fileExperienceReleiving: any;
  submittedMoreQualification = false;
  personalInfoData: any;
  qualificationData: any;
  moreQualificationNo: any;
  editMoreQualification: any = false;
  user: any;
  submittedJobDetail: any = false;
  submittingJobDetail: any = false;
  submittedExperienceDetail: any = false;
  jobData: any;
  id: any;
  disableAll: any = false;
  applicationId: any;
  submittedApplicationDetail: any = false;
  applicationData: any;
  submittedVerify: any = false;
  experiencePresent: any = 0;
  workingHere = false;
  experienceDetails: any = [];
  experienceNo: any;
  editExperienceDetail: any = false;

  constructor(private fb: FormBuilder,
              private stateService: StateService,
              private _location: Location,
              private alertService: AlertService,
              public personalInfoService: PersonalInfoService,
              public qualificationService: QualificationService,
              private globalService: GlobalService,
              private jobDetailService: JobDetailService,
              private activatedRoute: ActivatedRoute,
              private candidateService: CandidateService,
              private applicationService: ApplicationService,
              private router: Router,
              public modalService: NgbModal) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.user = this.globalService.getSelf();
    this.refresh();
  }

  refresh() {
    this.personalInfoForm = this.fb.group({
      'full_name': ['', Validators.required],
      'email': [{
        value: '',
        disabled: true
      }, Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      'father_name': ['', Validators.required],
      'mother_name': ['', Validators.required],
      'contact_no': [{value: '', disabled: true}, Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
      'alternate_contact_no': ['', Validators.compose([Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
      'correspondence_address': ['', Validators.required],
      'correspondence_tehsil_town': ['', Validators.required],
      'correspondence_district': ['', Validators.required],
      'correspondence_state_id': ['', Validators.required],
      'correspondence_pin': ['', Validators.required],
      'permanent_address': ['', Validators.required],
      'permanent_tehsil_town': ['', Validators.required],
      'permanent_district': ['', Validators.required],
      'permanent_state_id': ['', Validators.required],
      'permanent_pin': ['', Validators.required],
      'photograph': ['', Validators.required],
      'govt_photo_id': ['', Validators.required],
    });
    this.qualificationDetailsForm = this.fb.group({
      'tenth_institution_name': ['', Validators.required],
      'tenth_board': ['', Validators.required],
      'tenth_year_of_passing': ['', Validators.required],
      'tenth_marking_scheme': ['', Validators.required],
      'tenth_obtained_percentage_cgpa': ['', Validators.required],
      'twelth_institution_name': [''],
      'twelth_board': [''],
      'twelth_year_of_passing': [''],
      'twelth_marking_scheme': [''],
      'twelth_obtained_percentage_cgpa': [''],
      'tenth_certificate': [''],
      'twelth_certificate': ['']
    });
    this.moreQualificationForm = this.fb.group({
      'more_institution_name': ['', Validators.required],
      'more_board_university': ['', Validators.required],
      'more_year_of_passing': ['', Validators.required],
      'more_course': ['', Validators.required],
      'more_branch': [''],
      'more_obtained_percenatge': ['', Validators.required],
      'completion_certificate': [''],
    });
    this.jobDetailForm = this.fb.group({
      'resume': [''],
      'job_interested_area': ['', Validators.required],
      'total_work_experience': ['', Validators.required]
    });
    this.experienceDetailsForm = this.fb.group({
      'company_name': ['', Validators.required],
      'designation': ['', Validators.required],
      'currently_working_here': ['', Validators.required],
      'joining_date': ['', Validators.required],
      'leaving_date': [''],
      'experience_relieving': [''],
      'ctc': ['', Validators.required],
    });
    this.personalInfoForm.patchValue({
      'email': (this.user && this.user.email) ? this.user.email : '',
      'contact_no': (this.user && this.user.contact) ? this.user.contact : ''
    });

    this.getStates();

    if (this.id) {
      this.detail(this.id);
      this.disableAllForms();
    } else {
      this.getPersonalInfo();
      this.getQualifications();
      this.getJobDetails();
    }
    this.getApplicationDetails();
  }

  disableAllForms() {
    this.disableAll = true;
    this.personalInfoForm.disable();
    this.qualificationDetailsForm.disable();
    this.moreQualificationForm.disable();
    this.jobDetailForm.disable();
  }

  getStates() {
    this.stateService.get().subscribe(data => {
      if (data && data['data'].length > 0) {
        this.states = data['data'];
      }
    });
  }

  getPersonalInfo() {
    this.submittingPersonalInfo = true;
    this.personalInfoService.get().subscribe(data => {
      this.personalInfoData = data;
      this.submittingPersonalInfo = false;
      this.patchPersonlInfoForm();
    }, error => {
      this.submittingPersonalInfo = false;
    });
  }

  getQualifications() {
    this.submittingQualificationDetails = true;
    this.qualificationService.get().subscribe(data => {
      this.qualificationData = data;
      this.submittingQualificationDetails = false;
      this.patchQualifications();
    }, error => {
      this.submittingQualificationDetails = false;
    });
  }

  getJobDetails() {
    this.submittingJobDetail = true;
    this.jobDetailService.get().subscribe(data => {
      this.jobData = data;
      this.submittingJobDetail = false;
      this.patchJobDetails();
    }, error => {
      this.submittingJobDetail = false;
    });
  }

  getApplicationDetails() {
    this.submittedApplicationDetail = true;
    let params = {};
    if (this.id) {
      params['candidate_id'] = this.id;
    }
    this.applicationService.get(params).subscribe(data => {
      if (data) {
        this.applicationData = data;
        this.applicationId = data.application_id
      }
      this.submittedApplicationDetail = false;
    }, error => {
      this.submittedApplicationDetail = false;
    });
  }

  patchPersonlInfoForm() {
    this.personalInfoForm.patchValue({
      'full_name': (this.personalInfoData && this.personalInfoData.full_name) ? this.personalInfoData.full_name : '',
      'email': (this.personalInfoData && this.personalInfoData.email) ? this.personalInfoData.email : '',
      'father_name': (this.personalInfoData && this.personalInfoData.father_name) ? this.personalInfoData.father_name : '',
      'mother_name': (this.personalInfoData && this.personalInfoData.mother_name) ? this.personalInfoData.mother_name : '',
      'contact_no': (this.personalInfoData && this.personalInfoData.contact_no) ? this.personalInfoData.contact_no : '',
      'alternate_contact_no': (this.personalInfoData && this.personalInfoData.alternate_contact_no) ? this.personalInfoData.alternate_contact_no : '',
      'correspondence_address': (this.personalInfoData && this.personalInfoData.correspondence_address) ? this.personalInfoData.correspondence_address : '',
      'correspondence_tehsil_town': (this.personalInfoData && this.personalInfoData.correspondence_tehsil_town) ? this.personalInfoData.correspondence_tehsil_town : '',
      'correspondence_district': (this.personalInfoData && this.personalInfoData.correspondence_district) ? this.personalInfoData.correspondence_district : '',
      'correspondence_state_id': (this.personalInfoData && this.personalInfoData.correspondence_state_id) ? this.personalInfoData.correspondence_state_id : '',
      'correspondence_pin': (this.personalInfoData && this.personalInfoData.correspondence_pin) ? this.personalInfoData.correspondence_pin : '',
      'permanent_address': (this.personalInfoData && this.personalInfoData.permanent_address) ? this.personalInfoData.permanent_address : '',
      'permanent_tehsil_town': (this.personalInfoData && this.personalInfoData.permanent_tehsil_town) ? this.personalInfoData.permanent_tehsil_town : '',
      'permanent_district': (this.personalInfoData && this.personalInfoData.permanent_district) ? this.personalInfoData.permanent_district : '',
      'permanent_state_id': (this.personalInfoData && this.personalInfoData.permanent_state_id) ? this.personalInfoData.permanent_state_id : '',
      'permanent_pin': (this.personalInfoData && this.personalInfoData.permanent_pin) ? this.personalInfoData.permanent_pin : '',
      'photograph': (this.personalInfoData && this.personalInfoData.photograph) ? this.personalInfoData.photograph.id : '',
      'govt_photo_id': (this.personalInfoData && this.personalInfoData.govt_photo_id) ? this.personalInfoData.govt_photo_id.id : '',
    });
    this.filePhotograph = {'url': this.personalInfoData.photograph['folder_name'] + '/' + this.personalInfoData.photograph['user_id'] + '/' + this.personalInfoData.photograph['name']};
    this.fileGovtPhotoId = {'url': this.personalInfoData.govt_photo_id['folder_name'] + '/' + this.personalInfoData.govt_photo_id['user_id'] + '/' + this.personalInfoData.govt_photo_id['name']};
  }

  patchQualifications() {
    this.qualificationDetailsForm.patchValue({
      'tenth_institution_name': (this.qualificationData && this.qualificationData.tenth_institution_name) ? this.qualificationData.tenth_institution_name : '',
      'tenth_board': (this.qualificationData && this.qualificationData.tenth_board) ? this.qualificationData.tenth_board : '',
      'tenth_year_of_passing': (this.qualificationData && this.qualificationData.tenth_year_of_passing) ? this.qualificationData.tenth_year_of_passing : '',
      'tenth_marking_scheme': (this.qualificationData && this.qualificationData.tenth_marking_scheme) ? this.qualificationData.tenth_marking_scheme : '',
      'tenth_obtained_percentage_cgpa': (this.qualificationData && this.qualificationData.tenth_obtained_percentage_cgpa) ? this.qualificationData.tenth_obtained_percentage_cgpa : '',
      'twelth_institution_name': (this.qualificationData && this.qualificationData.twelth_institution_name) ? this.qualificationData.twelth_institution_name : '',
      'twelth_board': (this.qualificationData && this.qualificationData.twelth_board) ? this.qualificationData.twelth_board : '',
      'twelth_year_of_passing': (this.qualificationData && this.qualificationData.twelth_year_of_passing) ? this.qualificationData.twelth_year_of_passing : '',
      'twelth_marking_scheme': (this.qualificationData && this.qualificationData.twelth_marking_scheme) ? this.qualificationData.twelth_marking_scheme : '',
      'twelth_obtained_percentage_cgpa': (this.qualificationData && this.qualificationData.twelth_obtained_percentage_cgpa) ? this.qualificationData.twelth_obtained_percentage_cgpa : '',
      'tenth_certificate': (this.qualificationData && this.qualificationData.tenth_certificate) ? this.qualificationData.tenth_certificate : '',
      'twelth_certificate': (this.qualificationData && this.qualificationData.twelth_certificate) ? this.qualificationData.twelth_certificate : '',
      'more_qualifications': (this.qualificationData && this.qualificationData.more_qualifications) ? this.qualificationData.more_qualifications : ''
    });
    if (this.qualificationData && this.qualificationData.tenth_certificate && this.qualificationData.tenth_certificate['folder_name']) {
      this.fileTenthCertificate = {'url': this.qualificationData.tenth_certificate['folder_name'] + '/' + this.qualificationData.tenth_certificate['user_id'] + '/' + this.qualificationData.tenth_certificate['name']};
    }
    if (this.qualificationData && this.qualificationData.twelth_certificate && this.qualificationData.twelth_certificate['folder_name']) {
      this.fileTwelthCertificate = {'url': this.qualificationData.twelth_certificate['folder_name'] + '/' + this.qualificationData.twelth_certificate['user_id'] + '/' + this.qualificationData.twelth_certificate['name']};
    }
    if (this.qualificationData && this.qualificationData['more_qualifications'] && this.qualificationData['more_qualifications'].length > 0) {
      this.moreQualifications = [];
      this.qualificationData['more_qualifications'].forEach(moreQualification => {
        this.moreQualifications.push({
          'more_board_university': (moreQualification && moreQualification['university']) ? moreQualification['university'] : '',
          'more_institution_name': (moreQualification && moreQualification['institution_name']) ? moreQualification['institution_name'] : '',
          'more_course': (moreQualification && moreQualification['course']) ? moreQualification['course'] : '',
          'more_branch': (moreQualification && moreQualification['branch']) ? moreQualification['branch'] : '',
          'more_obtained_percenatge': (moreQualification && moreQualification['obtained_percentage']) ? moreQualification['obtained_percentage'] : '',
          'more_year_of_passing': (moreQualification && moreQualification['year_of_passing']) ? moreQualification['year_of_passing'] : '',
          'completion_certificate': (moreQualification && moreQualification['completion_certificate']) ? moreQualification['completion_certificate'].id : '',
          'url': (moreQualification && moreQualification['completion_certificate']) ? {'url': moreQualification['completion_certificate'].folder_name + '/' + moreQualification['completion_certificate'].user_id + '/' + moreQualification['completion_certificate'].name} : '',
        });
      });
    }
  }

  patchJobDetails() {
    if (this.jobData) {
      this.jobDetailForm.patchValue({
        'resume': (this.jobData && this.jobData.resume && this.jobData.resume.id) ? this.jobData.resume.id : '',
        'job_interested_area': (this.jobData && this.jobData.job_interested_area) ? this.jobData.job_interested_area : '',
        'total_work_experience': (this.jobData && this.jobData.total_work_experience) ? this.jobData.total_work_experience : 0
      });
      this.experiencePresent = (this.jobData && this.jobData.total_work_experience) ? this.jobData.total_work_experience : 0;
      if (this.jobData && this.jobData.resume) {
        this.fileResume = {'url': this.jobData.resume['folder_name'] + '/' + this.jobData.resume['user_id'] + '/' + this.jobData.resume['name']};
      }
      if (this.jobData && this.jobData['experiences'] && this.jobData['experiences'].length > 0) {
        this.experienceDetails = [];
        this.jobData['experiences'].forEach(experience => {
          this.experienceDetails.push({
            'company_name': (experience && experience.company_name) ? experience.company_name : '',
            'designation': (experience && experience.designation) ? experience.designation : '',
            'currently_working_here': (experience && experience.currently_working_here) ? experience.currently_working_here : '',
            'joining_date': (experience && experience.joining_date && moment(experience.joining_date).format('YYYY-MM-DD')) ? moment(experience.joining_date).format('YYYY-MM-DD') : '',
            'leaving_date': (experience && experience.leaving_date && moment(experience.leaving_date).format('YYYY-MM-DD')) ? moment(experience.leaving_date).format('YYYY-MM-DD') : '',
            'ctc': (experience && experience.ctc) ? experience.ctc : '',
            'experience_relieving': (experience && experience.experience_relieving) ? experience.experience_relieving : '',
            'url': (experience && experience['experience_relieving']) ? {'url': experience['experience_relieving'].folder_name + '/' + experience['experience_relieving'].user_id + '/' + experience['experience_relieving'].name} : ''
          });
        });
      }
    }
  }

  addMoreQualification() {
    this.submittedMoreQualification = true;
    if (!this.moreQualificationForm.valid) {
      return;
    }
    if (this.submittedMoreQualification) {
      this.moreQualifications.push({
        'more_institution_name': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_institution_name) ? this.moreQualificationForm.value.more_institution_name : '',
        'more_board_university': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_board_university) ? this.moreQualificationForm.value.more_board_university : '',
        'more_year_of_passing': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_year_of_passing) ? this.moreQualificationForm.value.more_year_of_passing : '',
        'more_course': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_course) ? this.moreQualificationForm.value.more_course : '',
        'more_branch': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_branch) ? this.moreQualificationForm.value.more_branch : '',
        'more_obtained_percenatge': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_obtained_percenatge) ? this.moreQualificationForm.value.more_obtained_percenatge : '',
        'completion_certificate': (this.moreQualificationForm.value && this.moreQualificationForm.value.completion_certificate) ? this.moreQualificationForm.value.completion_certificate : '',
        'url': this.fileCompletetionCertificate
      });
      this.moreQualificationForm.reset();
      this.moreQualificationForm.patchValue({
        'more_institution_name': '',
        'more_board_university': '',
        'more_year_of_passing': '',
        'more_course': '',
        'more_branch': '',
        'more_obtained_percenatge': '',
        'completion_certificate': '',
        'url': ''
      });
      this.fileCompletetionCertificate = undefined;
      this.submittedMoreQualification = false;
      this.moreQualificationNo = undefined;
      this.editMoreQualification = false;
    } else {
      this.fileCompletetionCertificate = undefined;
      this.submittedMoreQualification = false;
    }
  }

  updateMoreQualification() {
    this.submittedMoreQualification = true;
    if (!this.moreQualificationForm.valid) {
      return;
    }
    if (this.submittedMoreQualification) {
      this.moreQualifications[this.moreQualificationNo] = {
        'more_institution_name': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_institution_name) ? this.moreQualificationForm.value.more_institution_name : '',
        'more_board_university': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_board_university) ? this.moreQualificationForm.value.more_board_university : '',
        'more_year_of_passing': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_year_of_passing) ? this.moreQualificationForm.value.more_year_of_passing : '',
        'more_course': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_course) ? this.moreQualificationForm.value.more_course : '',
        'more_branch': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_branch) ? this.moreQualificationForm.value.more_branch : '',
        'more_obtained_percenatge': (this.moreQualificationForm.value && this.moreQualificationForm.value.more_obtained_percenatge) ? this.moreQualificationForm.value.more_obtained_percenatge : '',
        'completion_certificate': (this.moreQualificationForm.value && this.moreQualificationForm.value.completion_certificate) ? this.moreQualificationForm.value.completion_certificate : '',
        'url': this.fileCompletetionCertificate
      };
      this.moreQualificationForm.reset();
      this.moreQualificationForm.patchValue({
        'more_institution_name': '',
        'more_board_university': '',
        'more_year_of_passing': '',
        'more_course': '',
        'more_branch': '',
        'more_obtained_percenatge': '',
        'completion_certificate': '',
        'url': ''
      });
      this.fileCompletetionCertificate = undefined;
      this.submittedMoreQualification = false;
      this.moreQualificationNo = undefined;
      this.editMoreQualification = false;
    } else {
      this.fileCompletetionCertificate = undefined;
      this.submittedMoreQualification = false;
      this.moreQualificationNo = undefined;
    }
  }

  editMoreCompletion(data, i) {
    this.moreQualificationForm.patchValue({
      'more_institution_name': (data && data['more_institution_name']) ? data['more_institution_name'] : '',
      'more_board_university': (data && data['more_board_university']) ? data['more_board_university'] : '',
      'more_year_of_passing': (data && data['more_year_of_passing']) ? data['more_year_of_passing'] : '',
      'more_course': (data && data['more_course']) ? data['more_course'] : '',
      'more_branch': (data && data['more_branch']) ? data['more_branch'] : '',
      'more_obtained_percenatge': (data && data['more_obtained_percenatge']) ? data['more_obtained_percenatge'] : '',
      'completion_certificate': (data && data['completion_certificate']) ? data['completion_certificate'] : ''
    });
    this.fileCompletetionCertificate = {'url': data['url'].url};
    this.moreQualificationNo = i;
    this.editMoreQualification = true;
  }

  deleteMoreCompletion(id) {
    this.moreQualifications.splice(id, 1);
  }

  editExperience(data, i) {
    this.experienceDetailsForm.patchValue({
      'company_name': (data && data.company_name) ? data.company_name : '',
      'designation': (data && data.designation) ? data.designation : '',
      'currently_working_here': (data && data.currently_working_here) ? data.currently_working_here : '',
      'joining_date': (data && moment(data.joining_date).format('YYYY-MM-DD')) ? moment(data.joining_date).format('YYYY-MM-DD') : '',
      'leaving_date': (data && moment(data.leaving_date).format('YYYY-MM-DD')) ? moment(data.leaving_date).format('YYYY-MM-DD') : '',
      'ctc': (data && data.ctc) ? data.ctc : '',
      'experience_relieving': (data && data.experience_relieving) ? data.experience_relieving : '',
      'url': this.fileExperienceReleiving
    });
    this.fileExperienceReleiving = {'url': data['url'].url};
    this.experienceNo = i;
    this.editExperienceDetail = true;
  }

  deleteExperience(id) {
    this.experienceDetails.splice(id, 1);
  }

  detail(id) {
    this.candidateService.detail(parseInt(id)).subscribe(data => {
      if (data) {
        if (data && data['personal_info']) {
          this.personalInfoData = data.personal_info;
          this.patchPersonlInfoForm();
        }
        if (data && data['qualification']) {
          this.qualificationData = data['qualification'];
          this.patchQualifications();
        }
        if (data && data['job_detail']) {
          this.jobData = data['job_detail'];
          this.patchJobDetails();
        }
      }
    }, error => {
      this.back();
    });
  }

  onFileChange(e, type) {
    this.onFileChanged = e;
    if (type === 'UPLOAD_PHOTOGRAPH') {
      this.filePhotographSelected = true;
    } else if (type === 'UPLOAD_GOVT_PHOTO_ID') {
      this.fileGovtPhotoIdSelected = true;
    } else if (type === 'UPLOAD_TENTH_CERTIFICATE') {
      this.fileTenthCertificateSelected = true;
    } else if (type === 'UPLOAD_TWELTH_CERTIFICATE') {
      this.fileTwelthCertificateSelected = true;
    } else if (type === 'UPLOAD_MORE_QUALIFICATION') {
      this.fileCompletetionCertificateSelected = true;
    } else if (type === 'UPLOAD_RESUME') {
      this.fileResumeSelected = true;
    } else if (type === 'UPLOAD_EXPERIENCE_RELIEVING') {
      this.fileExperienceReleivingSelected = true;
    }
    this.uploadFile(type);
  }

  uploadFile(type) {
    this.personalInfoService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
      if (type === 'UPLOAD_PHOTOGRAPH') {
        this.filePhotograph = data['data'];
        this.personalInfoForm.patchValue({
          'photograph': this.filePhotograph.id
        });
      } else if (type === 'UPLOAD_GOVT_PHOTO_ID') {
        this.fileGovtPhotoId = data['data'];
        this.personalInfoForm.patchValue({
          'govt_photo_id': this.fileGovtPhotoId.id
        });
      } else if (type === 'UPLOAD_TENTH_CERTIFICATE') {
        this.fileTenthCertificate = data['data'];
        this.qualificationDetailsForm.patchValue({
          'tenth_certificate': this.fileTenthCertificate.id
        });
      } else if (type === 'UPLOAD_TWELTH_CERTIFICATE') {
        this.fileTwelthCertificate = data['data'];
        this.qualificationDetailsForm.patchValue({
          'twelth_certificate': this.fileTwelthCertificate.id
        });
      } else if (type === 'UPLOAD_MORE_QUALIFICATION') {
        this.fileCompletetionCertificate = data['data'];
        this.moreQualificationForm.patchValue({
          'completion_certificate': this.fileCompletetionCertificate.id
        });
      } else if (type === 'UPLOAD_RESUME') {
        this.fileResume = data['data'];
        this.jobDetailForm.patchValue({
          'resume': this.fileResume.id
        });
      } else if (type === 'UPLOAD_EXPERIENCE_RELIEVING') {
        this.fileExperienceReleiving = data['data'];
        this.experienceDetailsForm.patchValue({
          'experience_relieving': this.fileExperienceReleiving.id
        });
      }
      this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    }, error => {
      this.alertService.showErrors('Something went wrong, please try again');
    });
    if (type === 'UPLOAD_PHOTOGRAPH') {
      this.filePhotographSelected = false;
    } else if (type === 'UPLOAD_GOVT_PHOTO_ID') {
      this.fileGovtPhotoIdSelected = false;
    } else if (type === 'UPLOAD_TENTH_CERTIFICATE') {
      this.fileTenthCertificateSelected = false;
    } else if (type === 'UPLOAD_TWELTH_CERTIFICATE') {
      this.fileTwelthCertificateSelected = false;
    } else if (type === 'UPLOAD_EXPERIENCE_RELIEVING') {
      this.fileExperienceReleivingSelected = false;
    }
  }

  openPhotograph() {
    window.open(this.baseUrl + this.filePhotograph['url'], '_blank');
  }

  openPhotoId() {
    window.open(this.baseUrl + this.fileGovtPhotoId['url'], '_blank');
  }

  openTenthCertificate() {
    window.open(this.baseUrl + this.fileTenthCertificate['url'], '_blank');
  }

  openTwelthCertificate() {
    window.open(this.baseUrl + this.fileTwelthCertificate['url'], '_blank');
  }

  openCompletetionCertificate() {
    window.open(this.baseUrl + this.fileCompletetionCertificate['url'], '_blank');
  }

  openMoreCompletetion(data) {
    window.open(this.baseUrl + data['url'].url, '_blank');
  }

  openExperienceRelieving() {
    window.open(this.baseUrl + this.fileExperienceReleiving['url'], '_blank');
  }

  openExperienceRelievingData(data) {
    window.open(this.baseUrl + data['url'].url, '_blank');
  }

  openResume() {
    window.open(this.baseUrl + this.fileResume['url'], '_blank');
  }

  savePersonalInfo() {
    this.submittedPersonalInfo = true;
    if (!this.personalInfoForm.valid) {
      return;
    }
    if (this.submittedPersonalInfo) {
      const params = {
        'full_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().full_name) ? this.personalInfoForm.getRawValue().full_name : '',
        'email': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().email) ? this.personalInfoForm.getRawValue().email : '',
        'father_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().father_name) ? this.personalInfoForm.getRawValue().father_name : '',
        'mother_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().mother_name) ? this.personalInfoForm.getRawValue().mother_name : '',
        'contact_no': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().contact_no) ? this.personalInfoForm.getRawValue().contact_no : '',
        'alternate_contact_no': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().alternate_contact_no) ? this.personalInfoForm.getRawValue().alternate_contact_no : '',
        'correspondence_address': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_address) ? this.personalInfoForm.getRawValue().correspondence_address : '',
        'correspondence_tehsil_town': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_tehsil_town) ? this.personalInfoForm.getRawValue().correspondence_tehsil_town : '',
        'correspondence_district': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_district) ? this.personalInfoForm.getRawValue().correspondence_district : '',
        'correspondence_state_id': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_state_id) ? this.personalInfoForm.getRawValue().correspondence_state_id : '',
        'correspondence_pin': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_pin) ? this.personalInfoForm.getRawValue().correspondence_pin : '',
        'permanent_address': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_address) ? this.personalInfoForm.getRawValue().permanent_address : '',
        'permanent_tehsil_town': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_tehsil_town) ? this.personalInfoForm.getRawValue().permanent_tehsil_town : '',
        'permanent_district': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_district) ? this.personalInfoForm.getRawValue().permanent_district : '',
        'permanent_state_id': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_state_id) ? this.personalInfoForm.getRawValue().permanent_state_id : '',
        'permanent_pin': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_pin) ? this.personalInfoForm.getRawValue().permanent_pin : '',
        'photograph': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().photograph) ? this.personalInfoForm.getRawValue().photograph : '',
        'govt_photo_id': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().govt_photo_id) ? this.personalInfoForm.getRawValue().govt_photo_id : '',
      };
      this.submittingPersonalInfo = true;
      this.personalInfoService.create(params).subscribe(data => {
        if (data) {
          this.submittingPersonalInfo = false;
          this.submittedPersonalInfo = false;
        }
      }, error => {
        this.submittingPersonalInfo = false;
        this.submittedPersonalInfo = false;
      });
    }
  }

  updatePersonalInfo() {
    this.submittedPersonalInfo = true;
    if (!this.personalInfoForm.valid) {
      return;
    }
    if (this.submittedPersonalInfo) {
      const params = {
        'full_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().full_name) ? this.personalInfoForm.getRawValue().full_name : '',
        'father_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().father_name) ? this.personalInfoForm.getRawValue().father_name : '',
        'mother_name': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().mother_name) ? this.personalInfoForm.getRawValue().mother_name : '',
        'alternate_contact_no': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().alternate_contact_no) ? this.personalInfoForm.getRawValue().alternate_contact_no : '',
        'correspondence_address': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_address) ? this.personalInfoForm.getRawValue().correspondence_address : '',
        'correspondence_tehsil_town': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_tehsil_town) ? this.personalInfoForm.getRawValue().correspondence_tehsil_town : '',
        'correspondence_district': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_district) ? this.personalInfoForm.getRawValue().correspondence_district : '',
        'correspondence_state_id': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_state_id) ? this.personalInfoForm.getRawValue().correspondence_state_id : '',
        'correspondence_pin': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().correspondence_pin) ? this.personalInfoForm.getRawValue().correspondence_pin : '',
        'permanent_address': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_address) ? this.personalInfoForm.getRawValue().permanent_address : '',
        'permanent_tehsil_town': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_tehsil_town) ? this.personalInfoForm.getRawValue().permanent_tehsil_town : '',
        'permanent_district': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_district) ? this.personalInfoForm.getRawValue().permanent_district : '',
        'permanent_state_id': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_state_id) ? this.personalInfoForm.getRawValue().permanent_state_id : '',
        'permanent_pin': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().permanent_pin) ? this.personalInfoForm.getRawValue().permanent_pin : '',
        'photograph': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().photograph) ? this.personalInfoForm.getRawValue().photograph : '',
        'govt_photo_id': (this.personalInfoForm.getRawValue() && this.personalInfoForm.getRawValue().govt_photo_id) ? this.personalInfoForm.getRawValue().govt_photo_id : '',
      };
      this.submittingPersonalInfo = true;
      this.personalInfoService.update(this.personalInfoData['id'], params).subscribe(data => {
        if (data) {
          this.submittingPersonalInfo = false;
          this.submittedPersonalInfo = false;
        }
      }, error => {
        this.submittingPersonalInfo = false;
        this.submittedPersonalInfo = false;
      });
    }
  }

  saveQualificationForm() {
    this.submittedQualificationDetails = true;
    if (!this.qualificationDetailsForm.valid) {
      return;
    }
    if (this.submittedQualificationDetails) {
      const params = {
        'tenth_institution_name': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_institution_name) ? this.qualificationDetailsForm.getRawValue().tenth_institution_name : '',
        'tenth_board': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_board) ? this.qualificationDetailsForm.getRawValue().tenth_board : '',
        'tenth_year_of_passing': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_year_of_passing) ? this.qualificationDetailsForm.getRawValue().tenth_year_of_passing : '',
        'tenth_marking_scheme': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_marking_scheme) ? this.qualificationDetailsForm.getRawValue().tenth_marking_scheme : '',
        'tenth_obtained_percentage_cgpa': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_obtained_percentage_cgpa) ? this.qualificationDetailsForm.getRawValue().tenth_obtained_percentage_cgpa : '',
        'twelth_institution_name': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_institution_name) ? this.qualificationDetailsForm.getRawValue().twelth_institution_name : '',
        'twelth_board': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_board) ? this.qualificationDetailsForm.getRawValue().twelth_board : '',
        'twelth_year_of_passing': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_year_of_passing) ? this.qualificationDetailsForm.getRawValue().twelth_year_of_passing : '',
        'twelth_marking_scheme': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_marking_scheme) ? this.qualificationDetailsForm.getRawValue().twelth_marking_scheme : '',
        'twelth_obtained_percentage_cgpa': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_obtained_percentage_cgpa) ? this.qualificationDetailsForm.getRawValue().twelth_obtained_percentage_cgpa : '',
        'tenth_certificate': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_certificate) ? this.qualificationDetailsForm.getRawValue().tenth_certificate : '',
        'twelth_certificate': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_certificate) ? this.qualificationDetailsForm.getRawValue().twelth_certificate : '',
        'more_qualifications': this.moreQualifications
      };
      this.submittingQualificationDetails = true;
      this.qualificationService.create(params).subscribe(data => {
        if (data) {
          this.submittingPersonalInfo = false;
          this.submittedQualificationDetails = false;
        }
      }, error => {
        this.submittingQualificationDetails = false;
        this.submittedQualificationDetails = false;
      });
    }
  }

  updateQualificationForm() {
    this.submittedQualificationDetails = true;
    if (!this.qualificationDetailsForm.valid) {
      return;
    }
    if (this.submittedQualificationDetails) {
      const params = {
        'tenth_institution_name': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_institution_name) ? this.qualificationDetailsForm.getRawValue().tenth_institution_name : '',
        'tenth_board': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_board) ? this.qualificationDetailsForm.getRawValue().tenth_board : '',
        'tenth_year_of_passing': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_year_of_passing) ? this.qualificationDetailsForm.getRawValue().tenth_year_of_passing : '',
        'tenth_marking_scheme': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_marking_scheme) ? this.qualificationDetailsForm.getRawValue().tenth_marking_scheme : '',
        'tenth_obtained_percentage_cgpa': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_obtained_percentage_cgpa) ? this.qualificationDetailsForm.getRawValue().tenth_obtained_percentage_cgpa : '',
        'twelth_institution_name': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_institution_name) ? this.qualificationDetailsForm.getRawValue().twelth_institution_name : '',
        'twelth_board': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_board) ? this.qualificationDetailsForm.getRawValue().twelth_board : '',
        'twelth_year_of_passing': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_year_of_passing) ? this.qualificationDetailsForm.getRawValue().twelth_year_of_passing : '',
        'twelth_marking_scheme': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_marking_scheme) ? this.qualificationDetailsForm.getRawValue().twelth_marking_scheme : '',
        'twelth_obtained_percentage_cgpa': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_obtained_percentage_cgpa) ? this.qualificationDetailsForm.getRawValue().twelth_obtained_percentage_cgpa : '',
        'tenth_certificate': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().tenth_certificate) ? this.qualificationDetailsForm.getRawValue().tenth_certificate.id : '',
        'twelth_certificate': (this.qualificationDetailsForm.value && this.qualificationDetailsForm.getRawValue().twelth_certificate) ? this.qualificationDetailsForm.getRawValue().twelth_certificate.id : '',
        'more_qualifications': this.moreQualifications
      };
      this.submittingQualificationDetails = true;
      this.qualificationService.update(this.qualificationData['id'], params).subscribe(data => {
        if (data) {
          this.submittingQualificationDetails = false;
          this.submittedQualificationDetails = false;
        }
      }, error => {
        this.submittingQualificationDetails = false;
        this.submittedQualificationDetails = false;
      });
    }
  }

  saveJobDetailForm(content) {
    this.submittedJobDetail = true;
    if (!this.jobDetailForm.valid) {
      return;
    }
    if (this.submittedJobDetail) {
      const params = {
        'resume': (this.jobDetailForm.getRawValue() && this.jobDetailForm.getRawValue().resume) ? this.jobDetailForm.getRawValue().resume : '',
        'job_interested_area': (this.jobDetailForm.getRawValue() && this.jobDetailForm.getRawValue().job_interested_area) ? this.jobDetailForm.getRawValue().job_interested_area : '',
        'total_work_experience': (this.jobDetailForm.getRawValue() && this.jobDetailForm.getRawValue().total_work_experience) ? this.jobDetailForm.getRawValue().total_work_experience : '',
        'experiences': this.experienceDetails
      };
      this.submittingJobDetail = true;
      this.jobDetailService.create(params).subscribe(data => {
        if (data) {
          this.submittingJobDetail = false;
          this.submittedJobDetail = false;
          this.applicationId = data.application_id;
          this.modalService.open(content, {'size': 'lg'});
        }
      }, error => {
        this.submittingJobDetail = false;
        this.submittedJobDetail = false;
      });
    }
  }

  updateJobDetailForm(content) {
    this.submittedJobDetail = true;
    if (!this.jobDetailForm.valid) {
      return;
    }
    if (this.submittedJobDetail) {
      const params = {
        'resume': (this.jobDetailForm.getRawValue() && this.jobDetailForm.getRawValue().resume) ? this.jobDetailForm.getRawValue().resume : '',
        'job_interested_area': (this.jobDetailForm.getRawValue() && this.jobDetailForm.getRawValue().job_interested_area) ? this.jobDetailForm.getRawValue().job_interested_area : '',
        'total_work_experience': (this.jobDetailForm.getRawValue() && this.jobDetailForm.getRawValue().total_work_experience) ? this.jobDetailForm.getRawValue().total_work_experience : '',
        'experiences': this.experienceDetails
      };
      this.submittedJobDetail = true;
      this.jobDetailService.update(this.jobData['id'], params).subscribe(data => {
        if (data) {
          this.submittedJobDetail = false;
          this.submittedJobDetail = false;
        }
      }, error => {
        this.submittedJobDetail = false;
        this.submittedJobDetail = false;
      });
    }
  }

  verify(status) {
    this.submittedVerify = true;
    if (this.submittedVerify) {
      const params = {
        'status': status
      };
      this.submittedVerify = true;
      this.applicationService.updateStatus(this.applicationData.id, params).subscribe(data => {
        this.submittedVerify = false;
        this.submittedVerify = false;
        this.navigateTo('students');
      }, error => {
        this.submittedVerify = false;
        this.submittedVerify = false;
      });
    }
  }

  addJobDetail() {
    this.submittedExperienceDetail = true;
    if (!this.experienceDetailsForm.valid) {
      return;
    }
    if (this.submittedExperienceDetail) {
      this.experienceDetails.push({
        'company_name': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.company_name) ? this.experienceDetailsForm.value.company_name : '',
        'designation': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.designation) ? this.experienceDetailsForm.value.designation : '',
        'currently_working_here': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.currently_working_here) ? this.experienceDetailsForm.value.currently_working_here : '',
        'joining_date': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.joining_date && moment(this.experienceDetailsForm.value.joining_date).format('YYYY-MM-DD')) ? moment(this.experienceDetailsForm.value.joining_date).format('YYYY-MM-DD') : '',
        'leaving_date': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.leaving_date && moment(this.experienceDetailsForm.value.leaving_date).format('YYYY-MM-DD')) ? moment(this.experienceDetailsForm.value.leaving_date).format('YYYY-MM-DD') : '',
        'ctc': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.ctc) ? this.experienceDetailsForm.value.ctc : '',
        'experience_relieving': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.experience_relieving) ? this.experienceDetailsForm.value.experience_relieving : '',
        'url': this.fileExperienceReleiving
      });
      this.experienceDetailsForm.reset();
      this.experienceDetailsForm.patchValue({
        'company_name': '',
        'designation': '',
        'currently_working_here': '',
        'joining_date': '',
        'leaving_date': '',
        'ctc': '',
        'experience_relieving': '',
        'url': ''
      });
      this.fileExperienceReleiving = undefined;
      this.submittedExperienceDetail = false;
      this.experienceNo = undefined;
      this.editExperienceDetail = false;
    } else {
      this.fileExperienceReleiving = undefined;
      this.submittedExperienceDetail = false;
    }
  }

  updateJobDetail() {
    this.submittedJobDetail = true;
    if (!this.experienceDetailsForm.valid) {
      return;
    }
    if (this.submittedJobDetail) {
      this.experienceDetails[this.experienceNo] = {
        'company_name': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.company_name) ? this.experienceDetailsForm.value.company_name : '',
        'designation': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.designation) ? this.experienceDetailsForm.value.designation : '',
        'currently_working_here': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.currently_working_here) ? this.experienceDetailsForm.value.currently_working_here : '',
        'joining_date': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.joining_date && moment(this.experienceDetailsForm.value.joining_date).format('YYYY-MM-DD')) ? moment(this.experienceDetailsForm.value.joining_date).format('YYYY-MM-DD') : '',
        'leaving_date': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.leaving_date && moment(this.experienceDetailsForm.value.leaving_date).format('YYYY-MM-DD')) ? moment(this.experienceDetailsForm.value.leaving_date).format('YYYY-MM-DD') : '',
        'ctc': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.ctc) ? this.experienceDetailsForm.value.ctc : '',
        'experience_relieving': (this.experienceDetailsForm.value && this.experienceDetailsForm.value.experience_relieving) ? this.experienceDetailsForm.value.experience_relieving : '',
        'url': this.fileExperienceReleiving
      };
      this.experienceDetailsForm.reset();
      this.experienceDetailsForm.patchValue({
        'company_name': '',
        'designation': '',
        'currently_working_here': '',
        'joining_date': '',
        'leaving_date': '',
        'ctc': '',
        'experience_relieving': '',
        'url': ''
      });
      this.fileExperienceReleiving = undefined;
      this.submittedExperienceDetail = false;
      this.experienceNo = undefined;
      this.editExperienceDetail = false;
    } else {
      this.fileExperienceReleiving = undefined;
      this.submittedExperienceDetail = false;
      this.experienceNo = undefined;
    }
  }

  download() {
    let userId = (this.user && this.user['user_type'] === 'CANDIDATE') ? this.user.userId : this.id;
    let url = environment.appUrl + 'generate-pdf/' + userId;
    window.open(url, '_blank');
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  currentlyWorking(event) {
    this.workingHere = (event.target.value && event.target.value === 'YES');
  }

  onChange(event) {
    this.experiencePresent = parseInt(event.target.value);
  }

  back() {
    this._location.back();
  }
}
