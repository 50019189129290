import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../shared/services/storage.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {CandidateService} from '../../shared/services/candidate.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  user: any;
  candidateCount: any = 0;
  programCount: any = 0;
  courseCount: any = 0;
  branchCount: any = 0;

  constructor(private candidateService: CandidateService,
              private router: Router,
              private _location: Location) {
  }

  ngOnInit() {
    this.user = StorageService.getItem('self');
    if (this.user.user_type === 'ADMIN') {
      this.candidatesCount();
    } else {
      this.navigateTo('placement');
    }
  }

  candidatesCount() {
    this.candidateService.count().subscribe(data => {
      this.candidateCount = data;
    });
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  back() {
    this._location.back();
  }
}
