import {Routes} from '@angular/router';
import {DashboardComponent} from '../../pages/dashboard/dashboard.component';
import {AuthGuard} from '../auth/auth-guard.service';
import {MyProfileComponent} from '../../pages/my-profile/my-profile.component';
import {PlacementComponent} from '../../pages/placement/placement.component';
import {CandidatesComponent} from '../../pages/candidates/candidates.component';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'changelog',
    loadChildren: () => import('../../changelog/changelog.module').then(m => m.ChangeLogModule)
  },
  /*{
    path: 'full-layout',
    loadChildren: () => import('../../pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
  }*/
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      title: 'Dashboard'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'candidates',
    component: CandidatesComponent,
    data: {
      title: 'Candidates'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'candidate/:id',
    component: PlacementComponent,
    data: {
      title: 'Student'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    data: {
      title: 'My Profile'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'placement',
    component: PlacementComponent,
    data: {
      title: 'Placement'
    },
    canActivate: [AuthGuard]
  }
];
