import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CandidateService} from '../../shared/services/candidate.service';
import {Router} from '@angular/router';
import {GlobalService} from '../../shared/services/global.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})

export class CandidatesComponent implements OnInit {
  candidates = [];
  showSearch = false;
  submitting = false;
  searchForm: FormGroup;
  user: any;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(private candidateService: CandidateService,
              private router: Router,
              private fb: FormBuilder,
              private globalService: GlobalService,
              private _location: Location) {
  }

  ngOnInit() {
    this.user = this.globalService.getSelf();
    if (this.user.user_type !== 'ADMIN') {
      this.back();
    }
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'name': [''],
      'email': [''],
      'contact': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.candidates = [];
    this.candidateService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.candidates = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        }
      }
    });
  }

  search() {
    let params = {
      'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
      'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
      'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
    };
    this.get(params);
  }

  updateStatus(status, data) {
    const params = {
      'status': status
    };
    this.candidateService.updateStatus(data.id, params).subscribe(data => {
      this.get();
    });
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  edit(id) {
    const url = 'candidate/' + id;
    this.router.navigateByUrl(url);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }

  back() {
    this._location.back();
  }
}
