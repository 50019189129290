import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalService} from '../../shared/services/global.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit {
  self: any;

  constructor(private router: Router,
              private globalService: GlobalService) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.self = this.globalService.getSelf();
  }
}
